<template>
  <div :style="slidePosition" class="w-100 h-100 z-8">
    <div
      iv
      class="position-absolute text-white "
      style="width:47.5%;left:62.5%;top:50%;transform: translate(-50%,-50%)"
    >
      <h3 class="font-vw-3-5 font-w-600 mb-0 fade15" style="opacity:0;">
        1995
      </h3>
      <h6 class="font-vw-2-5 font-w-600 fade15 my-0" style="opacity:0; ">
        SPACE ZAPS
      </h6>
      <p class="font-vw-1-35 text-15 mt-3" style="opacity:0; ">
        Every second, high-energy cosmic rays—protons and neutrons—bombard the
        Earth’s atmosphere. Cosmic rays can disrupt or destroy electronic
        equipment, from satellites to cell phones. TRIUMF’s Proton Irradiation
        Facility (PIF) and Neutron Irradiation Facility (NIF) enable
        researchers, in just minutes, to test the multi-year effects of space
        radiation on electronic equipment. Each year, PIF and NIF are used by
        almost 200 users from a variety of organizations—including MDA, Cisco,
        and NASA.
      </p>
    </div>
  </div>
</template>

<script>
import anime from "animejs"
import { normalize } from "@/utils/ranges"
import { slidePositionPlain } from "@/utils/slidePositioning"
export default {
  props: ["globalUnits", "slideIndex"],
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  mounted() {
    this.startLoop()
  },
  methods: {
    slide15(percentage) {
      let animation = anime.timeline({
        autoplay: false,
        loop: false,
        endDelay: 500
      })

      animation
        .add({
          targets: ".fade15",

          translateY: ["-50%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine",
          delay: (el, i) => 5000 + 30 * i
        })
        .add({
          targets: ".text-15",

          translateY: ["35%", "0%"],
          opacity: [0, 1],
          duration: 100,
          easing: "easeInOutSine"
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    },

    startLoop() {
      anime({
        targets: ".particle",
        width: ["32%", "35%"],
        direction: "alternate",
        loop: true,
        duration: 1200,
        easing: "easeInOutSine"
      })
    }
  },
  watch: {
    innerPosition() {
      this.slide15(this.innerPosition)
    }
  }
}
</script>

<style lang="scss" scoped></style>
